* {
  margin: 0
}
.flyout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;
}

.home-feature-box .fa {
  font-size: 6rem;
}

.home-feature-box span {
  display: block;
  color: #111;
  font-weight: bold;
  margin-top: 1.5rem;
}

.example-components-list li > a {
  color: #495057;
}

.example-components-list li:last-child > a {
  border-bottom: 0;
}

.example-components-list li > a .fa {
  color: rgba(0, 0, 0, .35);
  float: right;
}

body::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none;
}

.e-pivotview {
  width: 100%;
  height: 100%;
}

.sb-sample-content-area {
  min-height: -webkit-fill-available !important;
}
.control-section {
  min-height: -webkit-fill-available !important;
}

#PivotView_PivotFieldList_Wrapper{
  margin-top: 10%;
  margin-left: 10%;
}

.e-bigger #overviewgrid .statustxt{
  position: relative;
}

td.e-rowcell .statustxt.e-activecolor {
  color: #00cc00;
  top: 9px;
}

td.e-rowcell .statustxt.e-inactivecolor {
  color: #e60000;
  top: 9px;
}

td.e-rowcell .statustxt.e-tentativecolor {
  color:#9c9c21;
  top: 9px;
}

.statustemp.e-inactivecolor {
  background-color: #ffd7cc;
  width: 64px;
}

.statustemp.e-activecolor {
  background-color: #ccffcc;
  width: 64px;
}

.statustemp.e-tentativecolor {
  background-color: #FFFF99;
  width: 64px;
}

.statustxt.e-activecolor {
  color: #00cc00;
}

.statustxt.e-inactivecolor {
  color: #e60000;
}

.statustxt.e-tentativecolor {
  color: #9c9c21;
}

.statustemp {
  position: relative;
  height: 19px;
  border-radius: 5px;
  text-align: center
}

#btnColor {
  background-color: #2f3d5e !important;
}


/* Toast */
#toast_types button {
  margin: 5px;
  min-width: 160px;
  max-width: 160px;
}

#toast_types {
  text-align: center;
}

.toast-icons {
  font-family: 'Toast_icons' !important;
  speak: none;
  font-size: 55px;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#toast_type .e-toast-icon.e-icons {
  height: auto;
  font-size: 30px;
}

.toast-icons.e-success::before {
  content: "\e701";
}

.toast-icons.e-error::before {
  content: "\e700";
}

.toast-icons.e-info::before {
  content: "\e704";
}

.toast-icons.e-warning::before {
  content: "\e703";
}
.close{
  margin-right: 10px;
  margin-top: 5px;
}
.mainClass .container{
  max-width: 90% !important;
}